// The hero image can be added to the src folder with the HeroImage.png name.
// Logo image can be added to the src folder as Logo.png
// Favicon image can be added to the public folder as favicon.ico

// Project specific values that can be changed easily.
export const projectConfiguration = {
  // Product Key
  productKey: "songbuilder",

  // Content variables
  heroLineOne: "Make The ",
  heroLineTwo: "Next Hot",
  heroLineThree: "Beat",
  subtitle:
    "Use the power of AI with your choices to build an awesome track for your next listen.",

  // Colors
  backgroundColor: "#232323",
  primaryTextColor: "#ffffff",
  secondaryTextColor: "#ffffff",
  tertiaryTextColor: "#ffffff",
  inputBackgroundColor: "#ffffff",
  inputFocusBackgroundColor: "#ffffff",
  inputTextColor: "#ffffff",
  inputFocusTextColor: "#232323",
  punchColor: "#31b0b0", // Used as link color
  footerLinkColor: "#ffffff",
  buttonTextColor: "#ffffff",

  //   Fonts
  primaryFont: "Poppins",
  secondaryFont: "Inter",
};
